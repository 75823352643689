.printOnly,
.show-for-print {
  display: none !important; }

@media only print {
  .printOnly,
  .show-for-print {
    display: inherit !important; }
  .printHidden,
  .hide-for-print {
    display: none !important; }
  .no-pagebreak {
    page-break-inside: avoid; } }
